<template>
  <section class="projects">
    <div class="container">
      <div class="tags">
        <ul class="tagsList">
          <li v-for="tag in tags" :key="tag.key">
            <a v-on:click="selectedTag=tag.key;" :class="{'selected': tag.key==selectedTag}">{{tag.title}}</a>
          </li>
        </ul>

      </div>
      <div class="cards">
        <a v-for="project in projects" :key="project.id"  :class="{'card':true,'black':project.black===true}" v-if="project.tags.includes(selectedTag)" v-on:click="$router.push('/project/'+project.id);">
          <div class="bg">
            <div class="img" :style="'background-image: url('+project.image+');'" v-if="project.image!=null && project.image!=''"></div>
            <video preload="metadata" muted="muted" autoplay playsinline loop v-if="project.media!=null && project.media!=''">
              <source :src="project.media" type="video/mp4">
            </video>
          </div>
          <div class="ptags">
            <div class="ptag" v-for="tag in project.tags" :key="tag" v-if="tag!='all'">{{tags[tag].title}}</div>
          </div>
          <div class="title">{{project.title}}</div>
        </a>

      </div>
      
    </div>
  </section>
</template>
<script>
import tags from '../data/tags';
import projects from '../data/projects';

export default {
  name: 'projects',
  data(){return{
    selectedTag: 'all',
    tags:tags,
    projects:projects,
  }},
  components: {
  },
  beforeCreate : async function () {
  },
  mounted : function () {
  },
  beforeDestroy:function () {
  },
  methods: {
  }
}

</script>