<template>
    <div class="contactsPage">
      <section class="contacts">
        <div class="container">
          <div class="sun"></div>
          <div class="title">Starting a new project or want to collaborate with us?</div>
          <div class="details">
            <div class="detail_item">
              <div class="label">Mail</div><div class="value"><a target="_blank" href="mailto:hi@laika.team">hi@laika.team</a></div>
            </div>
            <div class="detail_item">
              <div class="label">Telegram</div><div class="value"><a target="_blank" href="https://t.me/hello.laika">/hello.laika</a></div>
            </div>
            <div class="detail_item">
              <div class="label">WhatsApp</div><div class="value"><a target="_blank" href="https://wa.me/hello.laika">@hello.laika</a></div>
            </div>
          </div>
        </div>
      </section>
    </div>
</template>
<script>

export default {
  components: {
  },
  name: 'Contacts',
  data(){return {
  }},
  methods: {
  },
}
</script>
