<template>
  <section class="home" id="home" v-scroll="onScroll">
    <div class="title marquee"><span><img src="../assets/images/branding.svg"></span></div>
    <div :class="{eyes:true,liked:liked}">
      <div class="eyeL">
        <div class="eyeSocket">
          <div class="eyeball" :style="'transform: rotate('+this.theta+'deg);'">
            <div class="pupil" :style="'transform: rotate('+(-this.theta)+'deg); right: '+right+'%;'"><div class="shine"></div></div>
          </div>
        </div>
        <div class="likeSocket">
          <div :class="{like:true,puls:puls}"></div>
        </div>
      </div>
      <div class="eyeR">
        <div class="eyeSocket">
          <div class="eyeball" :style="'transform: rotate('+this.theta+'deg);'">
            <div class="pupil" :style="'transform: rotate('+(-this.theta)+'deg); right: '+right+'%;'"><div class="shine"></div></div>
          </div>
        </div>
        <div class="likeSocket">
          <div :class="{like:true,puls:puls}"></div>
        </div>
      </div>
    </div>
    <a :class="{hButton:true, hide:bhide}" v-on:click="doLike()"></a>
  </section>
</template>
<script>

export default {
  name: 'home',
  data(){return{
    liked:false,
    mouse: {
        x: undefined,
        y: undefined
    },
    eyes: {
        x: undefined,
        y: undefined
    },
    window: {
        w: undefined,
        h: undefined
    },
    theta: 0,
    right: 17.17,// max 34//17.17%
    puls:false,
    bhide:false,
  }},
  components: {
  },
  beforeMount : async function () {
    window.addEventListener("mousemove", this.eventListener);
    window.addEventListener("resize", this.onResize);
  },
  mounted : function () {
    this.onResize();
  },
  beforeDestroy:function () {
    window.removeEventListener("mousemove", this.eventListener);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onScroll:function (e) {
      let home = document.querySelector('#home');
      if (window.scrollY > home.offsetHeight/2) {
        this.bhide=true;
      }else{
        this.bhide=false;
      }
    },
    eventListener:function (e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
      this.getTheta();
    },
    onResize:function () {
      let eyes = document.querySelector('.eyes');
      let eyeSocket = document.querySelector('.eyeSocket');
      
      this.eyes.x = eyes.getBoundingClientRect().left;
      this.eyes.y = Math.round(eyes.getBoundingClientRect().top+(eyeSocket.offsetHeight/2));

      this.window.w = window.innerWidth;
      this.window.h = window.innerHeight;
      this.getTheta();
    },
    getTheta:function () {
      if(!this.eyes.x||!this.eyes.y||!this.mouse.x||!this.mouse.y)return;
        let dx = this.mouse.x - this.eyes.x;
        let dy = this.mouse.y - this.eyes.y;
        this.theta = Math.round((Math.atan2(dy, dx) * 180) / Math.PI *100)/100;
        let he=Math.pow(dy,2);
        let wi=Math.pow(dx,2);
        let s = Math.sqrt(he+wi);
        let pwh= Math.round(Math.sqrt(Math.pow(this.window.w,2)+Math.pow(this.window.h,2))/2)/100;
        let pr=100-s/pwh;
        this.right=Math.round(pr*(17.17/100)*100)/100;
    },
    doLike:function () {
      this.liked=true;
      setTimeout(()=>{this.puls=true;},300)
      setTimeout(()=>{
        this.puls=false; this.liked=false;
      },3300)
    }
  }
}

</script>