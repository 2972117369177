<template>
    <div class="projectsPage">
      <projects/>
    </div>
</template>
<script>
import projects from "../sections/projects.vue";

export default {
  components: {
    projects:projects,
  },
  name: 'Projects',
  data(){return {
  }},
  methods: {
  },
}
</script>
