<template>
    <div class="mainPage">
      <home/>
      <homeDisc/>
      <projects/>
      <clients/>
    </div>
</template>
<script>
import home from "../sections/home.vue";
import HomeDisc from "../sections/home-disc.vue";
import projects from "../sections/projects.vue";
import clients from "../sections/clients.vue";

export default {
  components: {
    home:home,
    homeDisc:HomeDisc,
    projects:projects,
    clients:clients,
  },
  name: 'Main',
  data(){return {
  }},
  methods: {
  },
}
</script>
