<template>
  <section class="clients">
    <div class="container">
      <div class="title">Clients</div>
      <div class="items">
          <div :class="'groupItem state'+groupItem1state">
              <div class="item" v-for="client in clients[0]" :key="client.key">
                <img :src="client.image" :class="'client_'+client.key"/>
              </div>
          </div>
          <div :class="'groupItem state'+groupItem2state">
              <div class="item" v-for="client in clients[1]" :key="client.key">
                <img :src="client.image" :class="'client_'+client.key"/>
              </div>
          </div>
          <div :class="'groupItem state'+groupItem3state">
              <div class="item" v-for="client in clients[2]" :key="client.key">
                <img :src="client.image" :class="'client_'+client.key"/>
              </div>
          </div>
          <div :class="'groupItem state'+groupItem4state">
              <div class="item" v-for="client in clients[3]" :key="client.key">
                <img :src="client.image" :class="'client_'+client.key"/>
              </div>
          </div>
      </div>
      <div class="itemsMob">
          <div class="item" v-for="client in clients[0]" :key="client.key">
            <img :src="client.image" :class="'client_'+client.key"/>
          </div>
          <div class="item" v-for="client in clients[1]" :key="client.key">
            <img :src="client.image" :class="'client_'+client.key"/>
          </div>
          <div class="item" v-for="client in clients[2]" :key="client.key">
            <img :src="client.image" :class="'client_'+client.key"/>
          </div>
          <div class="item" v-for="client in clients[3]" :key="client.key">
            <img :src="client.image" :class="'client_'+client.key"/>
          </div>
      </div>
      
      
    </div>
  </section>
</template>
<script>
import clients from '../data/clients';
var intervalId1=null;
var intervalId2=null;
var intervalId3=null;
var intervalId4=null;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export default {
  name: 'clients',
  data(){return{
    clients:clients,
    groupItem1state:1,
    groupItem2state:1,
    groupItem3state:1,
    groupItem4state:1,
  }},
  components: {
  },
  beforeCreate : async function () {
  },
  mounted : function () {
    intervalId1 = setInterval(()=>{
      let len = this.clients[0].length;
      if(this.groupItem1state==len)this.groupItem1state=1;
      else this.groupItem1state++;
    },getRandomInt(3000,9000));
    intervalId2 = setInterval(()=>{
      let len = this.clients[1].length;
      if(this.groupItem2state==len)this.groupItem2state=1;
      else this.groupItem2state++;
    },getRandomInt(3000,9000));
    intervalId3 = setInterval(()=>{
      let len = this.clients[2].length;
      if(this.groupItem3state==len)this.groupItem3state=1;
      else this.groupItem3state++;
    },getRandomInt(3000,9000));
    intervalId4 = setInterval(()=>{
      let len = this.clients[3].length;
      if(this.groupItem4state==len)this.groupItem4state=1;
      else this.groupItem4state++;
    },getRandomInt(3000,9000));
  },
  beforeDestroy: function () {
    clearInterval(intervalId1);
    clearInterval(intervalId2);
    clearInterval(intervalId3);
    clearInterval(intervalId4);
  },
  methods: {
  }
}

</script>