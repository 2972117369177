<template>
  <div id="app">
    <appheader/>
    <router-view/>
  </div>
</template>
<script>
import appheader from "./sections/header.vue"

export default {
  name: 'app',
  components: {
    appheader:appheader
  },
  data(){return{
  }},
  methods: {
  }
}
</script>