<template>
  <div class="ps404">
    <div class="container">
      <div class="title">
        404
      </div>
      <div class="error">
        Страница не найдена
      </div>
      <div class="description">
        К сожалению, запрашиваемая вами страница была удалена или перемещена. Вы можете воспользоваться навигацией сайта или проверить введенный адрес в адресной строке.
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: '404',
  data(){return{
  }},
  components: {
  },
  beforeCreate : async function () {
  },
  mounted : function () {
  },
  beforeDestroy:function () {
  },
  methods: {
  }
}

</script>