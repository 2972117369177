<template>
    <div class="projectPage">
      <project/>
      <moreprojects/>
    </div>
</template>
<script>
import project from "../sections/project.vue";
import moreprojects from "../sections/moreprojects.vue";

export default {
  components: {
    project:project,
    moreprojects:moreprojects,
  },
  name: 'ProjectPage',
  data(){return {
  }},
  methods: {
  },
}
</script>
