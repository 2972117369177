<template>
  <section class="projects">
    <div class="container">
    <div class="mpTitle">More Projects</div>
      <div class="cards">
        <a v-for="project in projectSelected"  :class="{'card':true,'black':project.black===true}" :key="project.id" v-on:click="$router.push('/project/'+project.id);">
          <div class="bg">
            <div class="img" :style="'background-image: url('+project.image+');'" v-if="project.image!=null && project.image!=''"></div>
            <video preload="metadata" muted="muted" autoplay playsinline loop v-if="project.media!=null && project.media!=''">
              <source :src="project.media" type="video/mp4">
            </video>
          </div>
          <div class="ptags">
            <div class="ptag" v-for="tag in project.tags" :key="tag" v-if="tag!='all'">{{tags[tag].title}}</div>
          </div>
          <div class="title">{{project.title}}</div>
        </a>

      </div>
      
    </div>
  </section>
</template>
<script>
import tags from '../data/tags';
import projects from '../data/projects';

export default {
  name: 'moreprojects',
  data(){return{
    tags:tags,
    projects:projects,
    projectId:this.$route.params.id,
    projectSelected:[projects[this.$route.params.id - 2] || projects[projects.length-1],projects[this.$route.params.id] || projects[0]],
  }},
  created: function() {
    this.$watch(
      () => this.$route.params,
      (to, from) => {
        this.projectSelected = [projects[to.id - 2] || projects[projects.length-1],projects[to.id] || projects[0]];
      }
    )
  },
}

</script>