<template>
  <section class="homeDisc">
    <div class="container">
      <div class="text">
        Hello world, we are Laika Team!<br>
        We do design and branding all over the world. We strive<br>
        to make the brand experience that can’t be ignored.
      </div>

    </div>
  </section>
</template>
<script>

export default {
  name: 'HomeDisc',
  data(){return{
  }},
  components: {
  },
  beforeCreate : async function () {
  },
  mounted : function () {
  },
  beforeDestroy:function () {
  },
  methods: {
  }
}

</script>