<template>
  <section class="project" :key="componentKey">
    <div class="projectHeader" v-if="project.page">
      <div class="desk">
        <img :src="project.page.headerLink" v-if="project.page.headerType=='img'">
        <video preload="metadata" muted="muted" autoplay playsinline loop v-if="project.page.headerType=='media'">
          <source :src="project.page.headerLink" type="video/mp4">
        </video>
      </div>
      <div class="mob">
        <img :src="project.page.headerMobLink" v-if="project.page.headerType=='img'">
        <video preload="metadata" muted="muted" autoplay playsinline loop v-if="project.page.headerType=='media'">
          <source :src="project.page.headerMobLink" type="video/mp4">
        </video>
      </div>
    </div>
    <div class="container">
      <div class="projectMainData" v-if="project.page">
        <div class="cTitle">
          <div class="title">{{project.page.title}}</div>
          <div class="ptags">
            <div class="ptag" v-for="tag in project.tags" :key="tag" v-if="tag!='all'">{{tags[tag].title}}</div>
          </div>
        </div>
        <div class="cDesc" v-html="project.page.desc"></div>

      </div>
      <div class="content" v-if="project.page&&project.page.contant">
        <div class="cItem" v-for="item in project.page.contant" :key="item.id">
          <img :src="item.link" v-if="item.type=='img'">
          <div class="raw" v-if="item.type=='2img'">
            <div class="col"><img :src="item.link1"></div>
            <div class="col"><img :src="item.link2"></div>
          </div>
          <div class="text" v-if="item.type=='text'" v-html="item.text"></div>
          <div class="raw text" v-if="item.type=='2text'">
            <div class="col" v-html="item.text1"></div>
            <div class="col" v-html="item.text2"></div>
          </div>
          <video preload="metadata" muted="muted" autoplay playsinline loop v-if="item.type=='media'">
            <source :src="item.link" type="video/mp4">
          </video>
          <div class="raw" v-if="item.type=='2media'">
            <div class="col">
              <video preload="metadata" muted="muted" autoplay playsinline loop>
                <source :src="item.link1" type="video/mp4">
              </video>
            </div>
            <div class="col">
              <video preload="metadata" muted="muted" autoplay playsinline loop>
                <source :src="item.link2" type="video/mp4">
              </video>
            </div>
          </div>
          <div class="raw" v-if="item.type=='img2media'">
            <div class="col"><img :src="item.link1"></div>
            <div class="col">
              <video preload="metadata" muted="muted" autoplay playsinline loop>
                <source :src="item.link2" type="video/mp4">
              </video>
            </div>
          </div>
          <div class="raw" v-if="item.type=='media2img'">
            <div class="col">
              <video preload="metadata" muted="muted" autoplay playsinline loop>
                <source :src="item.link1" type="video/mp4">
              </video>
            </div>
            <div class="col"><img :src="item.link2"></div>
          </div>
        </div>
      </div>
      <div class="team" v-if="project.page&&project.page.team">
          <div class="raw">
            <div class="col"></div>
            <div class="col">
              <div class="title">Team</div>
              <div class="text" v-html="project.page.team"></div>
            </div>
          </div>

      </div>
    </div>
  </section>
</template>
<script>
import tags from '../data/tags';
import projects from '../data/projects';

export default {
  name: 'project',
  data(){return{
    tags:tags,
    selectedTag: 'all',
    projects:projects,
    // projectId:this.$route.params.id,
    project:projects[this.$route.params.id-1],
    componentKey: 0,
  }},
  components: {
  },
  beforeCreate : async function () {
    if(!this.$route.params.id) return this.$router.push('/projects');
  },
  created: function() {
    this.$watch(
      () => this.$route.params,
      (to, from) => {
        this.project = projects[to.id-1];
        this.componentKey++;
      }
    )
  },
}

</script>