<template>
    <header>
      <div class="container">
        <div class="raw">
          <div class="lCol">
            <a v-on:click="$router.push('/')"><div class="logo"></div></a>
          </div>
          <div class="rCol">
            <div class="menuShadow" id="menuShadow" v-on:click="closeMenu()"></div>
            <div class="menuOpen" id="menuOpen"><a v-on:click="openMenu()">Menu</a></div>
            <ul class="menu" id="topMenu">
              <li>
                <a v-on:click="$router.push('/about');closeMenu();" :class="{'selected':this.$route.name=='About'}">About</a>
              </li>
              <li>
                <a v-on:click="$router.push('/projects');closeMenu();" :class="{'selected':this.$route.name=='Projects'}">Projects</a>
              </li>
              <li>
                <a v-on:click="$router.push('/contacts');closeMenu();" :class="{'selected':this.$route.name=='Contacts'}">Contacts</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
</template>
<script>

export default {
  name: 'appheader',
  data(){return{
  }},
  components: {
  },
  beforeCreate : async function () {
  },
  mounted : function () {
  },
  beforeDestroy:function () {
  },
  methods: {
      openMenu:function(){
        if(!document.querySelector('#menuOpen').classList.contains('showMenu')){
          document.querySelector('#menuShadow').classList.add("showMenu");
          document.querySelector('#menuOpen').classList.add("showMenu");
          document.querySelector('#topMenu').classList.add("showMenu");
        }else this.closeMenu();
      },
      closeMenu:function(){
        document.querySelector('#menuShadow').classList.remove("showMenu");
        document.querySelector('#menuOpen').classList.remove("showMenu");
        document.querySelector('#topMenu').classList.remove("showMenu");
      }
  }
}

</script>