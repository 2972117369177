<template>
    <div class="aboutPage">
      <section class="about">
        <div class="container">
          <div class="desc"><b>Laika team</b><br class="sM"> is a community <br class="sM">of creators, designers and artists who are exploring visual ways to convey ideas. For each client, we try to be a support team and find new ways of visual communication with the consumer.</div>
          <div class="services">
            <div class="title">Services</div>
            <div class="items">
              <div class="item" v-for="service in services" :key="service">{{service}}</div>
            </div>
          </div>
          <div class="team">
            <div class="title">Team</div>
            <div class="items">
              <div class="item" v-for="item in team" :key="item.id">
                <div class="img" :style="'background-image: url('+item.image+');'"></div>
                <div class="name">{{item.name}}</div>
                <div class="position">{{item.position}}</div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <clients/>
    </div>
</template>
<script>
import clients from "../sections/clientsSimple.vue";
import services from '../data/services';
import team from '../data/team';

export default {
  components: {
    clients:clients,
  },
  name: 'About',
  data(){return {
    services:services,
    team:team,
  }},
  methods: {
  },
}
</script>
